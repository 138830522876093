<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar
        :title="title"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="px-6">
      <v-form ref="form" lazy-validation>
        <v-row class="pt-6">
          <v-text-field
            label="Namn"
            v-model="formValues.est_str_name"
            placeholder="Stenhård hårdrockscen"
            persistent-placeholder
            dense
            outlined
            background-color="white"
            :rules="[rules.required]">
          </v-text-field>
        </v-row>
      </v-form>

      <v-btn
        v-if="!isCreate"
        outlined
        color="red"
        class="mt-4"
        @click="onDelete"
        small>
        <v-icon left>mdi-trash-can-outline</v-icon>
        Ta bort scenen
      </v-btn>
    </v-container>

    <app-confirm
      :show="showDeleteConfirm"
      title="Ta bort scenen?"
      @onYes="onDeleteConfirm"
      @onNo="showDeleteConfirm = false">
    </app-confirm>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from '@vue/composition-api';
import useCore from '../../helpers/core';
import useValidation from '../../helpers/validation';
import ViewToolbar from '../../components/ViewToolbar.vue';
import Confirm from '../../components/Confirm.vue';

export default {
  name: 'eventStagePut',
  props: {
    id: {
      type: Number,
      required: false,
    }
  },
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
  },
  setup({ id }) {
    const router = require('@/router/router').default;

    const { callApi, showMessage } = useCore();
    const { rules } = useValidation();
    
    const isCreate = !id;
    const showDeleteConfirm = ref(false);

    const dataLoaded = ref(false);
    const title = ref('Scen');
    const form = ref(null);

    const formValues = reactive({
      est_str_name: '',
    });

    // Actions
    const onSave = () => {
      // Invalid? Exit
      const formIsValid = form.value.validate();

      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }

      // Submit Create/Update
      const method = isCreate ? 'post' : 'put';
      const path = isCreate ? `/cms/events/stages` : `/cms/events/stages/${id}`;
      callApi({ method, path, data: formValues })
        .then((res) => {
          showMessage(`${res.est_str_name} har sparats`);
          router.replace({ name: 'eventStage' });
        })
        .catch((err) => {
          return err;
        });
    };
    const onDelete = () => {
      showDeleteConfirm.value = true;
    }
    const onDeleteConfirm = () => {
      callApi({ method: 'delete', path: `/cms/events/stages/${id}` })
        .then(() => {
          showMessage('Scen har tagits bort');
          router.replace({ name: 'eventStage' });
        })
        .catch((err) => {
          return err;
        });
    };

    // Toolbar actions
    const toolbarButtons = [{ icon: 'check', name: 'Spara', fn: onSave }]
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({ method: 'get', path: `/cms/events/stages/${id}` })
        .then((res) => {
          dataLoaded.value = true;
          // No record found; Exit
          if (res.noRecord) { return; }
          title.value = `Redigera ${res.est_str_name}`;
          // Record found; Populate form
          formValues.est_str_name = res.est_str_name;
        })
        .catch((err) => {
          router.replace({ name: 'eventStage' });
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      showDeleteConfirm,
      onDeleteConfirm,

      dataLoaded,
      form,
      rules,
      title,
      isCreate,
      formValues,
      toolbarButtons,
      onToolbarButton,
      onDelete,
    };
  },
};
</script>

<style>
</style>